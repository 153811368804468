import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Button = ({ bgColor, bgColorHover, label, to }) => (
  <Link
    to={to}
  >
    <button
      className={`
        bg-${bgColor}
        font-medium
        pb-2
        pl-5
        pr-5
        pt-2
        rounded-md
        text-gray-100
        hover:bg-${bgColorHover}
        transition
        duration-500
        ease-in-out
      `}
    >
      {label}
    </button>
  </Link>
)

Button.propTypes = {
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
}

Button.defaultProps = {
  bgColor: 'blue-800',
  bgColorHover: 'blue-900',
  label: '',
  to: '/',
}

export default Button