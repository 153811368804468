import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import MenuIcon from "@material-ui/icons/Menu"

import useViewport from "../helpers/useViewport"
import Button from "../elements/button"
import SubHeader from "./subHeader"

const Header = ({
  navButtons,
  navItems,
  siteTitle,
  subNavItems,
  subNavTitle,
  subHeaderColor,
}) => {
  const { width } = useViewport()
  const breakpoint = 1024
  const [showMenu, setShowMenu] = useState(width < breakpoint ? false : true)

  return (
    <header
      className="
        fixed
        w-screen
        z-20
      "
    >
      <div
        className="
          bg-gray-100
          border-b
          border-blue-800
        "
      >
        <div
          className="
            lg:flex
            lg:flex-row
            items-center
            justify-between
            max-w-6xl
            ml-auto
            mr-auto
            p-3
          "
        >
          <div
            className="
              flex
              flex-row
              justify-between
            "
          >
            <h1
              className="
                font-black
                text-2xl
                text-blue-800
                tracking-wide
              "
            >
              <Link
                className="
                  flex
                "
                to="/"
              >
                <img
                  className="
                    w-6
                    mr-2
                  "
                  src={'/images/icons/podlove-logo--blue.png'}
                />
                {siteTitle}
              </Link>
            </h1>
            {width < breakpoint && (
              <button
                className="
                  bg-blue-800
                  px-4
                  rounded-md
                  text-gray-100
                  hover:bg-blue-900
                  transition
                  duration-500
                  ease-in-out
                "
                onClick={() => setShowMenu(!showMenu)}
              >
                <MenuIcon />
              </button>
            )}
          </div>
          {(showMenu || width > breakpoint) && (
            <div
              className="
                lg:flex
                font-medium
                items-center
                text-gray-500
                tracking-wide
              "
            >
              {navItems.map(item => (
                <div key={JSON.stringify(item)}>
                  {item.path && !item.isExternal && (
                    <Link
                      activeStyle={{ color: "#051729", fontWeight: 800 }}
                      className="
                        block
                        px-5
                        py-3
                        hover:text-gray-800
                        transition
                        duration-500
                        ease-in-out
                        lg:static
                        lg:py-2
                      "
                      to={item.path}
                    >
                      {item.name}
                    </Link>
                  )}
                  {item.path && item.isExternal && (
                    <a
                      className="
                        block
                        px-5
                        py-3
                        hover:text-gray-800
                        transition
                        duration-500
                        ease-in-out
                        lg:static
                        lg:py-2
                      "
                      href={item.path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  )}
                  {!item.path && (
                    <div
                      className="
                        group
                        px-5
                        pt-3
                        relative
                        lg:py-5
                        lg:py-2
                        lg:rounded-md
                        lg:rounded-b-none
                      "
                    >
                      {item.name}
                      <div
                        className="
                          flex
                          flex-col
                          flex-wrap
                          px-5
                          py-5
                          space-y-3
                          lg:rounded-md
                          lg:z-10
                          lg:w-48
                          lg:left-0
                          lg:top-100
                          lg:text-gray-300
                          lg:absolute
                          lg:bg-blue-500
                          lg:invisible
                          lg:group-hover:visible
                        "
                      >
                        {item.navItems.map(item => (
                          <Link
                            activeStyle={{ color: "#FFF", fontWeight: 800 }}
                            key={JSON.stringify(item)}
                            className="
                              lg:hover:text-gray-100
                            "
                            to={item.path}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {navButtons.map(item => (
                <Button
                  key={JSON.stringify(item)}
                  label={item.name}
                  to={item.path}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {width > breakpoint && subNavItems && subNavItems.length > 0 && (
        <SubHeader
          color={subHeaderColor}
          navItems={subNavItems}
          title={subNavTitle}
        />
      )}
    </header>
  )
}

Header.propTypes = {
  navButtons: PropTypes.array,
  navItems: PropTypes.array,
  siteTitle: PropTypes.string,
  subNavItems: PropTypes.array,
  subHeaderColor: PropTypes.string,
  subNavTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
