import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const SubHeader = ({ navItems, title, color }) => (
  <div
    className="
      bg-white
      bg-opacity-50
    "
  >
    <div
      className="
        md:flex
        md:flex-row
        items-center
        justify-between
        max-w-6xl
        ml-auto
        mr-auto
        p-3
      "
    >
      <div>
        <h1
          className={`font-black text-l text-${color} tracking-wide uppercase`}
        >
          <p>
            {title}
          </p>
        </h1>
      </div>
      <div
        className="
          md:flex
          md:flex-row
          font-medium
          items-center
          space-x-10
          text-gray-500
          text-sm
          tracking-wide
        "
      >
        { navItems.map(item => 
          <Link
            className="
              pb-2
              pt-2
              hover:text-gray-800
              transition
              duration-500
              ease-in-out
            "
            key={JSON.stringify(item)}
            to={item.path}
          >
            {item.name}
          </Link>
        )}
      </div>
    </div>
  </div>
)

SubHeader.propTypes = {
  navItems: PropTypes.array,
  color: PropTypes.string,
  title: PropTypes.string,
}

SubHeader.defaultProps = {
  title: '',
}

export default SubHeader
