/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { MENU_DATA } from '../content/menu'

import Headline from '../elements/headline'
import Footer from './footer'
import Header from './header'

const Layout = ({ children, hero, subHeaderNavItems, subHeaderNavTitle, subHeaderColor }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header
        navButtons={MENU_DATA.navButtons}
        navItems={MENU_DATA.navItems}
        subNavItems={subHeaderNavItems}
        subNavTitle={subHeaderNavTitle}
        subHeaderColor={subHeaderColor}
        siteTitle={data.site.siteMetadata.title}
      />
      <main
        className="
          flex
          flex-col
          flex-wrap
          items-center
          min-h-screen
          overflow-x-hidden
          relative
        "
      >
        { hero &&
          <div
            className="
              bg-blue-100
              flex
              flex-col
              items-center
              justify-center
              mt-16
              px-16
              py-20
              md:py-48
              text-center
              w-full
            "
          >
            <Headline level={1}>{hero[0]}</Headline>
            <p
              className="
                font-mono
                text-blue-900
                max-w-2xl
              "
            >{hero[1]}</p>
          </div>
        }
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.array,
  subHeaderNavItems: PropTypes.array,
  subHeaderNavTitle: PropTypes.string,
  subHeaderColor: PropTypes.string,
}

export default Layout
