export const MENU_DATA = {
  navButtons: [
    {
      name: 'Donations',
      path: '/donations',
    }
  ],
  navItems: [
    {
      name: 'Blog',
      path: '/blog',
    },
    {
      name: 'Projects',
      navItems: [
        {
          name: 'Web Player',
          path: '/podlove-web-player'
        },
        {
          name: 'Publisher',
          path: '/podlove-podcast-publisher'
        },
        /* {
          name: 'Publisher PLUS',
          path: '/podlove-podcast-publisher-plus'
        }, */
        {
          name: 'Subscribe Button',
          path: '/podlove-subscribe-button'
        },
      ],
    },
    {
      name: 'Docs & Support',
      path: '/docs',
    },
    {
      name: 'Specifications',
      navItems: [
        {
          name: 'Simple Chapters',
          path: '/simple-chapters'
        },
        {
          name: 'Alternate Feeds',
          path: '/alternate-feeds'
        },
        {
          name: 'Paged Feeds',
          path: '/paged-feeds'
        },
        {
          name: 'Deep Linking',
          path: '/deep-linking'
        },
      ],
    },
    {
      name: 'About',
      path: '/about',
    },
    {
      name: 'Podcast',
      path: 'https://podlovers.org',
      isExternal: true,
    },
    /* {
      name: 'Contact',
      path: '/contact',
    }, */
  ]
}
