import React from 'react'
import bmbf from '../images/bmbf-logo.png'

const Footer = () => (
  <footer
    className="
      bg-blue-900
      md:flex
      justify-between
      p-5
      md:p-20
      text-white
    "
  >
    <div
      className="
        md:space-y-2
        md:w-1/3
      "
    >
      <p>
        <a
          href="https://fosstodon.org/@podlove"
          target="_blank"
          rel="noreferrer"
        >
          @podlove on Mastodon
        </a>
        <span className="text-gray-500"> – We toot sometimes</span>
      </p>
      <p>
        <a href="https://github.com/podlove" target="_blank" rel="noreferrer">
          Podlove on GitHub
        </a>
        <span className="text-gray-500"> – Checkout our Open Source Code</span>
      </p>
      <p>
        <a
          href="https://opencollective.com/podlove"
          target="_blank"
          rel="noreferrer"
        >
          Podlove on Open Collective
        </a>
        <span className="text-gray-500"> – Support Podlove</span>
      </p>
      <p>
        <a
          href="https://community.podlove.org/"
          target="_blank"
          rel="noreferrer"
        >
          Podlove Community
        </a>
        <span className="text-gray-500">
          {" "}
          – Our Suport Forum for your Questions
        </span>
      </p>
    </div>
    <div
      className="
        md:space-y-2
        md:w-1/3
        flex justify-center
        content-center
        flex-col
        self-center"
    >
      <img className="h-40 block self-center" 
        src={bmbf} 
      />
      <span className="text-white text-sm self-center mt-1">[Funding 01IS24S04]</span>
    </div>
    <div
      className="
        md:space-y-2
        text-right
        md:w-1/3
      "
    >
      <p>© {new Date().getFullYear()} Podlove</p>
      <p>
        <a href="/contact">Contact</a>
      </p>
      <p>
        <a href="/privacy">Privacy & Data Protection</a>
      </p>
      <p>
        <a href="/legal">Legal / Impressum</a>
      </p>
    </div>
  </footer>
)

export default Footer
