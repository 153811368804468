import PropTypes from 'prop-types'
import React from 'react'

const Headline = ({ children, color = 'fern', level }) => {
  if (level === 1) {
    return (
      <h1
        className={`
          font-black
          mt-5
          mb-5
          text-${color}
          text-5xl
        `}
      >
        {children}
      </h1>
    )
  }
  
  if (level === 2) {
    return (
      <h2
        className={`
          width-full
          font-black
          mt-5
          mb-5
          text-${color}
          text-4xl
          max-w-3xl
          z-10
        `}
      >
        {children}
      </h2>
    )
  }
  
  return (
    <h3
      className={`
        mt-5
        mb-5
        text-${color}
        text-xl
        max-w-2xl
        z-10
      `}
    >
      {children}
    </h3>
  )
}

Headline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  color: PropTypes.string,
  content: PropTypes.array,
  level: PropTypes.number,
}

Headline.defaultProps = {
  content: [],
  level: 1,
}

export default Headline